import React from "react"
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Heading,
  Input,
  Stack,
  Text,
  Textarea,
  useTheme,
} from "@chakra-ui/core"

import Layout from "../components/layout"

const Contact = props => {
  const theme = useTheme()
  return (
    <Layout>
      <Box w="100%" textAlign="center" p="6">
        <Box maxW={theme.breakpoints.lg} mx="auto">
          <Heading textAlign="center" color="pink.800" mb="4">
            Ota yhteyttä
          </Heading>
          <Text color="pink.800">
            Voit soittaa meille numeroon 041 318 1305, laittaa sähköpostia
            osoitteeseen <a href="mailto:info@onnistua.fi">info@onnistua.fi</a>{" "}
            tai käyttää alla olevaa lomaketta. Pyrimme vastaamaan mahdollisimman
            pian.
          </Text>
          <form
            action="/kiitos"
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input
              type="hidden"
              name="form-name"
              value="contact"
              width="400px"
            />
            <Stack>
              <FormLabel>
                Nimi
                <Input type="text" name="name" size="lg" />
              </FormLabel>
              <FormLabel>
                Sähköpostiosoite
                <Input type="email" name="email" size="lg" />
              </FormLabel>
              <FormLabel>
                Viesti
                <Textarea type="text" name="message" size="lg" />
              </FormLabel>
              <Button type="submit">Lähetä</Button>
            </Stack>
          </form>
        </Box>
      </Box>
    </Layout>
  )
}

export default Contact
